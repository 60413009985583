import BasicLayout from '@/layout/BasicLayout';
import * as Router from 'vue-router';

export const router = Router.createRouter({
    history: Router.createWebHashHistory(),
    linkExactActiveClass: 'active',
    routes: [
      {
        path: '/',
        redirect: 'start',
        component: BasicLayout,
        children: [
          {
            path: '/start',
            name: 'start',
            component: () => import(/* webpackChunkName: "kw" */ './pages/AskRefreshVoucher.vue')
          },
          {
            path: '/refresh-voucher',
            name: 'refresh-voucher',
            component: () => import(/* webpackChunkName: "kw" */ './pages/RefreshVoucherInfo.vue')
          }
        ]
      }
    ]
});
  