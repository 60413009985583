<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
@import "~primevue/resources/primevue.min.css";
@import "~primevue/resources/themes/md-light-deeppurple/theme.css";
</style>
