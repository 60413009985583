<template>
  <div
    class="main-content flex flex-col min-h-screen min-w-screen space-y-3 p-5"
  >
    <!-- HEADER -->
    <div
      class="flex flex-row justify-center items-center bg-white rounded-lg drop-shadow-2xl p-5"
    >
      <router-link :to="{ name: 'start' }"
        ><img
          src="../assets/KW_LOGO_COMPLETE_PANTONE_WITH_TOOTH.png"
          class="h-auto"
      /></router-link>
    </div>
    <!-- MAIN CONTENT -->
    <div>
      <router-view></router-view>
    </div>
    <!-- HEADER -->
    <div
      class="flex flex-col justify-center items-center bg-white rounded-lg drop-shadow-2xl p-5"
    >
      <span
        >&copy;
        <a href="https://konzept-weiss.de"
          >KONZEPT WEISS Zahnaufhellung GmbH</a
        ></span
      >
      <div class="flex flex-row justify-center items-center space-x-3">
        <a href="https://konzept-weiss.de/pages/impressum" target="_blank"
          >Impressum</a
        >
        <span>|</span>
        <a
          href="https://konzept-weiss.de/pages/datenschutzerklaerung"
          target="_blank"
          >Datenschutzerklärung</a
        >
      </div>
    </div>
    <CookieBannerModal
      ref="cookieBanner"
      @closeDialog="checkCookieSetting"
    ></CookieBannerModal>
  </div>
</template>

<script>
import CookieBannerModal from "../components/modal/CookieBanner.vue";
import { appConstants } from "@/constants/app";
import { accountServiceInstance } from "@/helper/account-api-axios";

export default {
  name: "BasicLayout",
  beforeCreate() {
    document.title = "KONZEPT WEISS | Refresh";
  },
  mounted() {
    this.checkCookieSetting();
  },
  components: {
    CookieBannerModal,
  },
  methods: {
    checkCookieSetting() {
      const isCookieAllow = localStorage.getItem(appConstants.COOKIEKEY);
      if (!isCookieAllow) {
        this.$refs.cookieBanner.open();
        return;
      }

      this.checkRefreshVoucherReactivateParameter();
    },
    checkRefreshVoucherReactivateParameter() {
      try {
        const routeData = this.$route.query?.rvr;
        let localStorageRefreshVoucherReactivateData = localStorage.getItem(
          appConstants.REFRESH_VOUCHER_REACTIVATE_DATA
        );
        let refreshVoucherReactivateData = null;

        if (routeData) {
          const encryptData = window.atob(window.atob(routeData));
          const routeRefreshVoucherReactivateData = JSON.parse(encryptData);

          if (!localStorageRefreshVoucherReactivateData) {
            localStorage.setItem(
              appConstants.REFRESH_VOUCHER_REACTIVATE_DATA,
              routeData
            );
          }

          localStorageRefreshVoucherReactivateData = JSON.parse(
            window.atob(window.atob(localStorageRefreshVoucherReactivateData))
          );
          if (
            localStorageRefreshVoucherReactivateData.token ===
            routeRefreshVoucherReactivateData.token
          ) {
            refreshVoucherReactivateData = routeData;
            return;
          }

          localStorage.setItem(
            appConstants.REFRESH_VOUCHER_REACTIVATE_DATA,
            routeData
          );
        } else if (
          localStorageRefreshVoucherReactivateData !== undefined &&
          localStorageRefreshVoucherReactivateData !== "undefined" &&
          localStorageRefreshVoucherReactivateData !== null
        ) {
          refreshVoucherReactivateData = JSON.parse(
            window.atob(window.atob(localStorageRefreshVoucherReactivateData))
          );
        }

        if (refreshVoucherReactivateData !== null) {
          this.checkRefreshVoucherReactivate(refreshVoucherReactivateData);
        }
      } catch (error) {
        console.error(error);
      }
    },
    checkRefreshVoucherReactivate(data) {
      const url = "/api/voucher-public/refresh/reactivate/valid";
      accountServiceInstance
        .post(url, data)
        .then((result) => {
          if (!result.data.valid) {
            localStorage.removeItem(
              appConstants.REFRESH_VOUCHER_REACTIVATE_DATA
            );
          }
        })
        .catch(() => {
          localStorage.removeItem(appConstants.REFRESH_VOUCHER_REACTIVATE_DATA);
        });
    },
  },
};
</script>
<style>
.main-content {
  /* background-image:linear-gradient(cornflowerblue 39%, wheat 56%); */
  background: linear-gradient(200deg, #2dce89 90%, #172b4d 25%);
}

img {
  max-width: 200px;
}
</style>
