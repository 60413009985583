import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import "./index.css";
import "primeicons/primeicons.css";
import { router } from "./app.routing";
import VueTheMask from "vue-the-mask";

const app = createApp(App);

app.use(PrimeVue);
app.use(router);
app.use(VueTheMask);
app.mount("#app");
