<template>
  <Dialog v-model:visible="display" :closeOnEscape="false" :closable="false">
    <template #header>
      <h3>Cookies</h3>
    </template>
    <div class="flex flex-col w-full justify-center items-center space-y-3">
      <p>
        Diese Website verwendet Cookies, um sicherzustellen, dass Du die beste
        Erfahrung auf unserer Website erhältst. Weitere Informationen hierzu
        finden Sie in unserer
        <a href="https://konzept-weiss.de/pages/datenschutzerklaerung"
          >Datenschutzerklärung</a
        >.
      </p>
      <div class="flex flex-col w-full space-y-3 divide-y" v-if="showSetting">
        <p>Wähle welche Art von Cookies du akzeptieren möchtest.</p>
        <div class="flex flex-row justify-between items-center p-3">
          <div>
            <p>Erforderlich</p>
            <p>
              Diese Cookies sind nicht optional. Sie werden benötigt, damit die
              Website funktioniert.
            </p>
          </div>
          <InputSwitch :modelValue="true" />
        </div>
        <div class="flex flex-row justify-between items-center p-3">
          <div>
            <p>Statistiken</p>
            <p>
              Damit wir die Funktionalität und Struktur der Website verbessern
              können, basierend darauf, wie die Website genutzt wird.
            </p>
          </div>
          <InputSwitch v-model="statistics" />
        </div>
        <div class="flex flex-row justify-between items-center p-3">
          <div>
            <p>Erfahrung</p>
            <p>
              Damit unsere Website während Ihres Besuchs so gut wie möglich
              funktioniert. Wenn du diese Cookies ablehnst, werden einige
              Funktionen von der Website verschwinden.
            </p>
          </div>
          <InputSwitch v-model="experience" />
        </div>
        <div class="flex flex-row justify-between items-center p-3">
          <div>
            <p>Marketing</p>
            <p>
              Indem du deine Interessen und dein Verhalten beim Besuch unserer
              Website mitteilst, erhöhst du die Chance, personalisierte Inhalte
              und Angebote zu sehen.
            </p>
          </div>
          <InputSwitch v-model="marketing" />
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        label="Nur notwendige Cookies erlauben"
        class="p-button-text text-gray-500"
        @click="allowRequiredCookies"
      />
      <Button
        label="Einstellung"
        class="p-button-text text-gray-500"
        @click="showSetting = true"
        v-if="!showSetting"
      />
      <Button
        label="Speichern"
        class="p-button-text bg-kw text-white"
        @click="allowSettingCookies"
        v-if="showSetting"
      />
      <Button
        label="Alle akzeptieren"
        class="p-button-text bg-kw text-white"
        @click="allowAllCookies"
      />
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputSwitch from "primevue/inputswitch";
import { appConstants } from "@/constants/app";

export default {
  name: "CookieBanner",
  data() {
    return {
      display: false,
      showSetting: false,
      statistics: false,
      experience: false,
      marketing: false,
    };
  },
  components: {
    Dialog,
    Button,
    InputSwitch,
  },
  methods: {
    open() {
      this.display = true;
    },
    close() {
      this.display = false;
      this.$emit("closeDialog");
    },
    allowAllCookies() {
      localStorage.setItem(
        appConstants.COOKIEKEY,
        JSON.stringify({
          statistics: true,
          experience: true,
          marketing: true,
        })
      );
      this.close();
    },
    allowRequiredCookies() {
      localStorage.setItem(
        appConstants.COOKIEKEY,
        JSON.stringify({
          statistics: false,
          experience: false,
          marketing: false,
        })
      );
      this.close();
    },
    allowSettingCookies() {
      localStorage.setItem(
        appConstants.COOKIEKEY,
        JSON.stringify({
          statistics: this.statistics,
          experience: this.experience,
          marketing: this.marketing,
        })
      );
      this.close();
    },
  },
};
</script>

<style>
.p-dialog-title {
  background: #2dce89;
}
</style>
