import axios from "axios";

const apiUrl = process.env.VUE_APP_API_URL;
const apiKey = process.env.VUE_APP_API_KEY;
const httpHeader = {
    'api-authorization': apiKey
};

export const accountServiceInstance = axios.create({ 
    baseURL: apiUrl,
    headers: httpHeader 
});